import hasPermi from './component/hasPermi'
import hasRole from './component/hasRole'
import hasPermiDisable from './component/hasPermiDisable'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function directive(app: { directive: (arg0: string, arg1: { mounted(el: HTMLElement, binding: { value: any }): void }) => void }) {
  app.directive('hasPermi', hasPermi)
  app.directive('hasRole', hasRole)
  app.directive('hasPermiDisable', hasPermiDisable)
}
