import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useDictStore = defineStore('dict', () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dict = ref<any>([])
  const getDict = (_key: string) => {
    if (_key == null && _key == '') {
      return null
    }
    try {
      for (let i = 0; i < dict.value.length; i++) {
        if (dict.value[i].key == _key) {
          return dict.value[i].value
        }
      }
    } catch (e) {
      return null
    }
  }
  // 设置字典
  const setDict = (_key: string | null, value: string) => {
    if (_key !== null && _key !== '') {
      dict.value.push({
        key: _key,
        value: value
      })
    }
  }
  // // 删除字典
  const removeDict = (_key: string) => {
    let bln = false
    try {
      for (let i = 0; i < dict.value.length; i++) {
        if (dict.value[i].key == _key) {
          dict.value.splice(i, 1)
          return true
        }
      }
    } catch (e) {
      bln = false
    }
    return bln
  }

  const cleanDict = () => {
    dict.value = []
  }

  return {
    getDict,
    setDict,
    removeDict,
    cleanDict
  }
})
