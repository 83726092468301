import { ResponseData } from '@/interface/request'
import instance from '@/plugins/request'

export const login = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/login',
    method: 'post',
    data
  })
}
// 获取微信登录url
export const redirect = (): Promise<ResponseData> => {
  return instance.request({
    url: '/redirect',
    method: 'get'
  })
}
// 获取微信登录url
export const getMiniImage = (): Promise<ResponseData> => {
  return instance.request({
    url: '/wxAuth/getQrCode',
    method: 'get'
  })
}

export const getImageList = (): Promise<ResponseData> => {
  return instance.request({
    url: '/getCaptchaImageUrl',
    method: 'get'
  })
}

export const authScanCode = (params: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/wxAuth/checkAuth',
    method: 'get',
    params
  })
}

export const getTenantList = (params: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/system/tenant/getUserAllTenantList',
    method: 'get',
    params
  })
}

export const checkTenant = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/saveUserTenant',
    method: 'post',
    headers: {
      Authorization: ''
    },
    data
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkTenantagain = (data: unknown, token: any): Promise<ResponseData> => {
  return instance.request({
    url: '/saveUserTenant',
    method: 'post',
    headers: {
      Authorization: token || ''
    },
    data
  })
}
// 发送验证码
export const sendCode = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/sendVerificationCode',
    method: 'post',
    data
  })
}

// 手机号登录
export const loginPhoneNumber = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    url: '/authPhone',
    method: 'post',
    data
  })
}

// 退出方法
export const logout = (): Promise<ResponseData> => {
  return instance.request({
    url: '/logout',
    method: 'post'
  })
}

export const getInfo = (): Promise<ResponseData> => {
  return instance.request({
    url: '/getInfo',
    method: 'get'
  })
}

export const getRouters = (): Promise<ResponseData> => {
  return instance.request({
    url: '/getRouters',
    method: 'get'
  })
}
// 切换公司获取所属租户
export const gettenant = (): Promise<ResponseData> => {
  return instance.request({
    url: '/system/tenant/getUserAllTenantList',
    method: 'get'
  })
}
