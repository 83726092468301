<template>
  <div
    class="bg-shade-6 rounded-md object-cover overflow-hidden"
    ref="loadImage"
    :class="str"
    :style="{ width: props.width + 'px', height: props.height + 'px' }"
  ></div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick, watch, onBeforeUnmount } from 'vue'
import defaultImage from '@/assets/images/default_user_avatar.svg'

const loadImage = ref()
const str = ref<string | undefined>()

const props = defineProps({
  src: {
    type: String,
    default: defaultImage
  },
  width: {
    type: Number,
    default: 60
  },
  height: {
    type: Number,
    default: 60
  }
})

const generateClass = () => {
  return 'load-image' + Math.floor(Math.random() * 1000)
}
str.value = generateClass()

const initPage = () => {
  console.log(str.value)
  nextTick(() => {
    if (loadImage.value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dom: any = document.querySelector('.' + str.value)
      const children = dom.children
      for (let i = 0; i < children.length; i++) {
        dom?.removeChild(children[i])
      }
      const image = new Image()
      image.style.width = '100%'
      image.style.height = '100%'
      image.src = props.src
      image.onload = () => {
        dom?.appendChild(image)
      }
      image.onerror = () => {
        image.src = defaultImage
        dom?.appendChild(image)
      }
    }
  })
}

const watchClose = watch(
  () => props.src,
  (v1: string) => {
    console.error(v1)

    if (v1) {
      initPage()
    }
  }
)

onMounted(() => {
  initPage()
})
onBeforeUnmount(() => {
  watchClose()
})
</script>
