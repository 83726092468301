import { createApp } from 'vue'
import 'nprogress/nprogress.css'
import { createPinia } from 'pinia'
import router from '@/router/index'
import '@/router/permission'
import dayjs from '@/plugins/day'
import SvgIcon from '@/component/SvgIcon/index'
import 'virtual:svg-icons-register'
import '@/assets/styles/index.less'

import Cookies from 'js-cookie'
import { useDict } from '@/utils/dict'
import directive from '@/directives' // directive1212321
import App from './App.vue'
import BrandCrumb from '@/component/BrandCrumb/index.vue'
import iconfont from '@/component/iconfont/index'
import modal from '@/utils/modal'
import Divide from '@/component/Divider/index.vue'
import loadImage from '@/component/LoadImage/index.vue'
import '@/assets/iconfont/iconfont'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cApp: any = createApp(App)

// 全局方法挂载
cApp.config.globalProperties.useDict = useDict
cApp.config.globalProperties.modal = modal
// if (import.meta.env.MODE == 'development') {
//   cApp.config.performance = true
// }
// cApp.config.globalProperties.useDict = useDict

cApp.config.globalProperties.dayjs = dayjs
cApp.config.globalProperties.cookie = Cookies
// eslint-disable-next-line @typescript-eslint/no-explicit-any
cApp.config.errorHandler = (err: any, vm: any, info: any) => {
  console.error(err, vm, info)
}

cApp.use(router).use(createPinia()).use(SvgIcon).component('brand-crumb', BrandCrumb).component('Divide', Divide).component('load-image', loadImage).use(iconfont)

directive(cApp)

cApp.mount('#app')
