import { h } from 'vue'
import { Modal } from '@arco-design/web-vue'
import ModalVue from '@/component/Modal/Modal.vue'

/**
 * @function info:默认弹窗/success:成功弹窗/warning:警告弹窗/error:错误弹窗
 * |—@params title: string 标题
 * |—@params content: string 内容
 * |—@params type: boolean 是否开启关闭图标
 * |—@params maskClosable: boolean 是否开启点击遮罩层关闭
 */

interface option {
  title?: string
  content?: string
  type?: boolean
  onOk?: () => void
  onCancel?: () => void
  maskClosable?: boolean
}
const info = (options: option) => {
  let title = options?.title,
    content = options?.content,
    type = options?.type
  title ? '' : (title = '系统提示')
  content ? '' : (content = '确认操作？')
  type ? '' : (type = false)

  Modal.info({
    ...options,
    title: title,
    content: content,
    closable: true,
    hideCancel: false,
    titleAlign: 'start',
    modalClass: type ? 'info-class-simple' : 'info-class',
    simple: type
  })
}
const success = (options: option) => {
  let title = options?.title,
    content = options?.content,
    type = options?.type
  title ? '' : (title = '系统操作')
  content ? '' : (content = '确认操作？')
  type ? '' : (type = false)

  Modal.success({
    ...options,
    title: title,
    content: content,
    closable: true,
    hideCancel: false,
    titleAlign: 'start',
    modalClass: type ? 'info-class-simple' : 'info-class',
    simple: type
  })
}
const warning = (options: option) => {
  let title = options?.title,
    content = options?.content,
    type = options?.type
  title ? '' : (title = '系统警告')
  content ? '' : (content = '确认操作？')
  type ? '' : (type = false)
  return Modal.warning({
    ...options,
    title: title ? title : '系统操作',
    content: content ? content : '确认操作？',
    closable: true,
    hideCancel: false,
    titleAlign: 'start',
    modalClass: type ? 'info-class-simple' : 'info-class',
    simple: type
  })
}
const error = (options: option) => {
  let title = options?.title,
    content = options?.content,
    type = options?.type
  title ? '' : (title = '系统操作')
  content ? '' : (content = '确认操作？')
  type ? '' : (type = false)
  Modal.error({
    ...options,
    title: title ? title : '系统操作',
    content: content ? content : '确认操作？',
    closable: true,
    hideCancel: false,
    titleAlign: 'start',
    modalClass: type ? 'info-class-simple' : 'info-class',
    simple: type
  })
}

/**
 * @function result:结果弹窗
 * |— @params options:Object 配置项
 *    注：options.type: "success" | "error" | "warning" | "info" | "403" | "404" | "500" | null | undefined 控制弹出层类型
 * |—|— @params onClose: () => void 关闭弹出层函数
 * @if options.type === 'succcess',定时器提示文字传入：props.content和props.pathName
 * @else options.type === "error" | "warning" | "info" | "403" | "404" | "500" | null | undefined
 *        定时器提示文字传入：props.message
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const result = (options: any) => {
  const modal = Modal.confirm({
    content: () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      h(ModalVue as any, {
        ...options,
        onClose() {
          modal.close()
        }
      }),
    maskClosable: false,
    closable: false,
    footer: false
  })
}

const toggleSwitch = ({ title, content }: { title?: string; content?: string }) => {
  return new Promise((resolve, reject) => {
    warning({
      title: title,
      content: content,
      onOk() {
        resolve(true)
      },
      onCancel() {
        reject()
      }
    })
  })
}

export default { info, success, warning, error, result, switch: toggleSwitch }
