/**
 * v-hasPermi 操作权限处理
 */

import { userInfoStore } from '@/store/userInfo'

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mounted(el: HTMLElement, binding: { value: any }) {
    const { value } = binding
    const all_permission = '*:*:*'
    const permissions = userInfoStore().permissions
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value
      const hasPermissions = permissions.some((permission: string) => {
        return all_permission == permission || permissionFlag.includes(permission)
      })

      if (!hasPermissions && el.parentNode) {
        el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
